<template>
    <div>
        <span style="margin-right:5px;" v-b-modal="name+'-push-notifications-history-modal-' + profile._id" @click="activeModalBtn(name+`-push-notifications-history-modal-'${profile._id}`)">
            <div class="d-flex gap-1">
                <!-- <div>
                    <img width="14" src="/gem-icon.png" alt="gem-icon">
                </div> -->
                <span>
                    {{ profile.userNotificationCount }}
                </span>
            </div>
        </span>
        <b-modal :id="name+'-push-notifications-history-modal-' + profile._id" hide-footer size="lg" centered @hidden="resetModal">
            <template #modal-title>
                <div class="custom-header">
                <h5>
                    <!-- <img width="14" style="margin-right: 5px;" src="/gem-icon.png" alt="gem-icon"> -->
                    Notification History
                    ({{ profile.name }})
                </h5>
                </div>
            </template>
            <div class="modal_content">
                
                <b-overlay :show="show" rounded="sm">
                    <div v-if="pushNotifications.total > 0" class="mt-0">
                        <div v-for="(itemData,indexData) in pushNotifications.data" :key="indexData" class="mb-1">
                            <div>
                                <div>{{ itemData.message }}</div>
                                <div>{{ itemData.sendingStatus }}</div>
                            </div>
                        </div>
                        <div class="mt-1 mb-1">
                            <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
                                <div class="dataTables_info mt-1">Showing {{pushNotifications.from}} to {{pushNotifications.to}} of {{pushNotifications.total}} entries</div>
                                <pagination 
                                class="mt-1 mb-0"
                                :data="pushNotifications"
                                :limit=5
                                @pagination-change-page="getProfileGemsHistory"
                                ></pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        No Record Found!
                    </div>
                </b-overlay>
                
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BOverlay,
    BLink,
    BBadge,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BThead,
    BFormCheckbox,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BCardHeader,
    BCardBody,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BOverlay,
        BLink,
        BBadge,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTableSimple,
        BThead,
        BFormCheckbox,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BCardHeader,
        BCardBody,
        BSpinner,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
            Helpers,
            activeModalName: null,
            pushNotifications: {},
            show: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        activeModalBtn(modalName){
            this.activeModalName = modalName;
            this.getProfileGemsHistory();
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        getProfileGemsHistory: function (page = 1) {
            this.show = true;
            var url = process.env.VUE_APP_API_URL + `/profile-push-notifications-history/${this.profile._id}?page=${page}`;
            var payload = {};
            axios
                .post(url, payload, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((res) => {
                    this.pushNotifications = res.data.pushNotifications;
                    this.show = false;
                })
                .catch(function (error) {
                    console.log("error", error);
                    this.show = false;
                });
        },
    },
    props: [
        "profile",
        "name"
    ],
};
</script>
