<template>
  <b-card  no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Show Online Users</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col  xl="3" sm="6">
            <h1>0</h1>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  data() {
    return {
      table_data: {},
    };
  },
  created() {
    // this.getTableData();
  },
  methods: {
    // getTableData() {
    //   axios
    //     .get(process.env.VUE_APP_API_URL + "/zape-cms/user-statictics", {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("_t"),
    //       },
    //     })
    //     .then((get_response) => {
    //       this.table_data = get_response.data
    //     });
    // },
  },
};
</script>
