<template>
  <b-card v-if="table_data" no-body class="card-statistics">
    <b-card-header>
      <b-card-title>App Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <!-- Updated 1 month ago -->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in table_data"
          :key="item.icon"
          xl="3"
          sm="6"
          style="margin: 10px"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                class="b-avatar badge-light-primary rounded-circle"
                variant="primary"
              >
                <!-- <feather-icon size="24" :icon="item.icon" />
                 -->
                <img
                  :src="item.icon"
                  style="width: 48px; height: 48px; object-fit: contain"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.data }}
              </h4>
              <b-card-text
                class="font-small-3 mb-0"
                v-if="item.name != 'Total Time'"
              >
                {{ item.name }}
              </b-card-text>
              <b-card-text class="font-small-3 mb-0" v-else>
                <!-- {{ item.name +' in minutes' }}  -->
                Total Minutes
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      table_data: {},
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/user-statictics", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;
        });
    },
  },
};
</script>
