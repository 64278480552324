<template>
  <b-card no-body :key="sidebarkey">
    <b-card-header>
      <b-card-title>Latest Statistics</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <!-- <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        /> -->
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <!-- {{latestBarChart.data}} -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="latestBarChart.data"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
// import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";
import { $themeColors } from "@themeConfig";
import axios from "axios";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      rangePicker: ["2019-05-01", "2019-05-10"],
      latestBarChart: {
        data: {
          labels: [2017, 2018, 2019, 2020, 2021, 2022, 2023],
          datasets: [
            {
              label: "All data add",
              type: "bar",
              stack: "Stack 0",
              backgroundColor: "#eece01",
              data: [30, 31, 32, 33, 34, 35, -36],
            },
            {
              label: "All data delete",

              type: "bar",

              stack: "Stack 0",
              backgroundColor: "#87d84d",
              data: [-30, -16, -17, 18, -19, 20, -21],
            },
            {
              label: "All data edit",
              type: "bar",

              stack: "Stack 0",
              backgroundColor: "#f8981f",
              data: [20, -21, 22, 23, 24, -25, 26],
            }
          ],
        },

        options: {
          responsive: true,
          legend: {
            display: true,
          },
          interaction: {
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
      sidebarkey:0,
    };
  },
  created() {
    this.dateRange = {};

    this.firstload();
    // console.log("this.dateRangeCreated",typeof(this.dateRange))
  },
  methods: {
    deselectFunction(data) {
      // console.log(0, value);
      // for (let k = 0; k < removed.length; k++) {
      // const element = array[k];
      this.filterEmpty(data.value);
      let conditionValue = false;
      for (let l = 0; l < this.optionsData.length; l++) {
        // const element = array[l];
        if (this.optionsData[l].value == data.value) {
          conditionValue = false;
          // console.log("condition true");
          break;
        } else {
          conditionValue = true;
        }
      }
      if (conditionValue) {
        // console.log("save value");

        this.optionsData.push(data);
      }
      // }
      this.forcRender();
      var finalData = {
        id: data.value,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/REMOVE_SELECTED_USERS_LOGIN_USERS",
          finalData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(respo)
        });
    },
    selectFunction(data) {
      // console.log(1,value);
      this.singleUserSpinner = true;
      var userIdsArr = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].value) {
          userIdsArr.push(data[i].value);
          //
        }
      }
      // this.selectedUsers =  "";
      var finalData = {
        id: userIdsArr,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/GET_ALL_SINGLE_USER_ADD_QUESTION_REPORT",
          finalData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(this.permantSaveOptionValue, "sfasfas");
          // this.optionsData = response.data.remaingUsers;
          // this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              UserId: "",
            };
            // alert(_.some( this.lineChart.data.datasets,response.data.data[index]));
            // if(_.some( this.lineChart.data.datasets,response.data.data[index])){

            //   }else{
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.UserId = response.data.data[index].UserId;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            // alert(_.some(this.lineChart.data.datasets, createObject));
            // }
            if (_.some(this.lineChart.data.datasets, createObject)) {
              // this.filterEmpty(createObject['UserId']);
            } else {
              this.lineChart.data.datasets.push(createObject);
            }
            // this.filterEmpty(response.data.data[index].lineColor);
          }
          // this.lineChart.data.datasets.concat(this.permantSaveOptionValue);
          this.forcRender();
          this.singleUserSpinner = false;
        });
    },
    filterEmpty(filterArr) {
      // console.log("valuefilter", filterArr);
      var index = this.lineChart.data.datasets
        .map((x) => {
          return x.UserId;
        })
        .indexOf(filterArr);

      // console.log("this.optionsDat/a",index);
      // if (index >= 0) {
      this.lineChart.data.datasets.splice(index, 1);
      // console.log("this.lineChart.data.datasets", this.lineChart.data.datasets);
      // this.previousSelectedStructures = [];
      // }
    },
    firstload() {
      this.spinnerPlayStop = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/GET_LAST_SEVEN_DAYS_ADD_QUESTION_REPORTS",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log('response.data',response.data)
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          this.previousSelectedStructures = this.selectedUsers;
          // let added = this.selectedUsers.filter(
          //   (val) => !this.previousSelectedStructures.includes(val)
          // );
          this.latestBarChart.data.labels = response.data.date;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              UserId:"",
              label: "",
              type: "bar",
              stack: "",
              backgroundColor: "",
              data: [],
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.stack = response.data.data[index].stack;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.UserId = response.data.data[index].UserId;
            newArr[index] = createObject;
          }
          // console.log('arr',newArr);
          this.latestBarChart.data.datasets = newArr;
          this.permantSaveOptionValue = JSON.stringify(
            this.latestBarChart.data.datasets
          );
          this.permantSaveOptionValue = JSON.parse(this.permantSaveOptionValue);
          var a = new Date();
          var b = -6;
          var resulta = a.setDate(a.getDate() + b);
          var someDate = new Date();
          var numberOfDaysToAdd = 0;
          var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
          this.dateRang = {};;
          this.dateRange = {
            endDate: new Date(result),
            startDate: new Date(resulta),
          };
          this.spinnerPlayStop = false;
          this.forcRender();
        });
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    getAddQuestionAccordingUsers(value) {
      this.spinnerPlayStop = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      if (startDate == lastDate) {
        var datetime = {
          date: `${startDate}`,
        };
      } else {
        var datetime = {
          date: `${startDate + " " + "to" + " " + lastDate}`,
        };
      }

      // console.log('datetime',datetime)
      axios
        .post(
          process.env.VUE_APP_API_URL + "/GET_ALL_PREVIEWS_DATE_QUESTION",
          datetime,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.data.datasets = newArr;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
